<template>
  <div class="all-height d-flex justify-center">
    <div class="all-width">
      <v-card v-if="typeOfUser === 'Free'">
          <v-card-text>
              <br>
              <br>
              <br>
              <div class="text-h5 text-center font-weight-bold" align-center>
                      This feature is available on paid version
              </div>
              <br>
              <br>
          </v-card-text>
        </v-card>
        <v-card class="" loader-height="2" :loading="loading" :disabled="loading" v-else>
        <v-card-text class="pb-0">
          <div class="d-flex align-center mb-2" v-if="!loading">
            <div>
              <v-chip v-if="stage === 3" small label color="success">Closed</v-chip>
              <v-chip v-else-if="stage === 1" small label color="warning">Pending for review</v-chip>
              <v-chip v-else small label color="btnColor" class="white--text">Open</v-chip>
            </div>
            <v-btn class="shadow-off ml-2" small icon @click="refreshData()"><v-icon>mdi-refresh</v-icon></v-btn>
            <v-spacer></v-spacer>
          </div>

          <div v-if="loading">
            <v-skeleton-loader
              type="table-heading, list-item-two-line, article, article"></v-skeleton-loader>
          </div>
          <div v-else class="mt-4">
            <div class="d-flex align-center mb-2">
              <v-btn small color="fbcPrimary" class="white--text" @click="addDialogBox()" v-if="$nova.hasRight(right, 'edit') || $nova.hasRight(right, 'add')">
                <v-icon class="mr-1">mdi-plus</v-icon>
                <span>New</span>
              </v-btn>
              <v-spacer></v-spacer>
            </div>
            <div class="mb-4" v-if="data.length > 0">
              <v-simple-table dense class="FC-Table">
                <thead>
                  <tr>
                    <th scope="description">Description</th>
                    <th scope="from">From</th>
                    <th scope="to">To</th>
                    <th scope="status">Status</th>
                    <th scope="createdon">Created on</th>
                    <th scope="createdon" class="text-center">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(v, k) in data" :key="k">
                    <td>{{ v.description }}</td>
                    <td>{{ $nova.formatDate(v.from) }}</td>
                    <td>{{ $nova.formatDate(v.to) }}</td>
                    <td>
                      <v-chip small label color="info" v-if="v.stage === 0">Open</v-chip>
                      <v-chip small label text color="success" class="" v-else-if="v.stage === 2">Close</v-chip>
                      <v-chip small label text color="error" v-else-if="v.stage === 1">freeze</v-chip>
                    </td>
                    <td>{{ $nova.formatDate(v.created_at) }}</td>
                    <td class="text-center">
                      <v-tooltip bottom content-class="tooltip-bottom">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn icon small color="" v-bind="attrs" v-on="on"
                            @click="$nova.gotoLink({ path: '/financialclose/project/'+id+'/varianceanalysis/analysis/' + v._id })">
                            <v-icon>mdi-eye</v-icon>
                          </v-btn>
                        </template>
                        <span>View</span>
                      </v-tooltip>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </div>
            <div class="mb-4 text grey--text title pa-8 text-center" v-else>
              No analysis added
            </div>
          </div>

        </v-card-text>
      </v-card>

      <lb-dailogboxnew v-model="adddialog" heading="Add New Variance Analysis" width="600" :loading="addloading">
        <template v-slot:body>
          <div>
            <div class="">
              <!-- <lb-dropdown label="Populate Data From*" v-model="vafrom"
                :error="addediterror.datafrom" :items="populatevaitems" itemtext="name"
                itemvalue="_id" /> -->
              <lb-dropdown label="Select Project*" v-model="vafromvalue" :items="formdata.projects" itemtext="description"
                itemvalue="_id" />
              <v-row>
                <v-col cols="6">
                  <lb-string v-model="addeditdata.amount1name" label="Amount 1 Column Name*"
                    :error="addediterror.amount1name" hidedetails />
                </v-col>
                <v-col cols="6">
                  <lb-string v-model="addeditdata.amount2name" label="Amount 2 Column Name*"
                    :error="addediterror.amount2name" hidedetails />
                </v-col>
                <v-col cols="6">
                  <lb-number class="mr-2" prependiconinner="mdi-cash" label="Materiality Amount" hidedetails
                    v-model="addeditdata.materialityamount1" min="0" />
                </v-col>
                <v-col cols="6">
                  <lb-number label="Materiality Percentage" prependiconinner="mdi-percent" step="0.01" hidedetails
                    v-model="addeditdata.materialitypercent1" min="0" />
                </v-col>
              </v-row>
              <div class="mt-4">
                <div class="font-weight-bold mr-2">Note:</div>
                <div>The Column Names filled above will be used to display the comparative. Eg: Actuls Vs Budget</div>
                <div>Variance analysis workflow will be triggered if either the Materiality Amount or Percentage is
                  crossed.</div>
              </div>
            </div>
          </div>
        </template>
        <template v-slot:actions>
          <span class="caption">*Required</span>
          <v-spacer></v-spacer>
          <v-btn small color="fbcPrimary" class="white--text" @click="addAnalysis()">Create</v-btn>
        </template>
      </lb-dailogboxnew>


    </div>
  </div>
</template>

<script>

export default {
  name: 'financialclose_varienceanalysis_list',
  // props: ['props'],
  data: function () {
    return {
      right:'financialclose_analysis',
      metadata: {},
      summary: {},
      data: [],
      userid: "",
      formdata: {},
      userlist: {},
      stage: -1,
      id: "",
      loading: false,
      addeditdata: {},
      addediterror: {},
      adddialog: false,
      addloading: false,
      vafrom: "",
      vafromvalue: "",
      populatevaitems: [
        { _id: "blank", name: "Blank" },
        { _id: "previousproject", name: "Previous Project" },
      ],
      typeOfUser: "Paid",
    }
  },
  components: {
  },
  created() {
    this.init();
    this.refreshData();
  },
  activated() {
    this.init();
    this.refreshData();
  },

  methods: {
    init() {
      if (this.$store.state.subscriptions.financialclose.plan_detail.name) {
        this.typeOfUser = this.$store.state.subscriptions.financialclose.plan_detail.name || "Paid";
      }
    },
    refreshData() {
      this.id = this.$route.params.id || "";
      this.userid = this.$store.state.user.id;
      this.getData();
    },
    getData() {
      this.loading = true;
      return this.axios.post("/v2/financialclose/analysis/getvarianceanalysisdetails/" + this.id).then(dt => {
        if (dt.data.status === "success" && dt.data.data.length > 0) {
          let data = dt.data.data[0] || {}
          this.metadata = data.metadata || {};
          this.data = data.metadata?.flux_projects_detail || [];
          this.stage = this.metadata.stage;
          this.summary = data.summary || {};
        }else throw Error("Error loading project");
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).then(() => {
        return this.axios.post("/v2/financialclose/analysis/getvarianceanalysisformdata/" + this.metadata._id);
      }).then(dt => {
        if (dt.data.status === "success") {
          this.formdata = dt.data.data[0] || {};
          this.userlist = {};
          let colors = this.$nova.getColors((this.formdata.users || []).length, 0.2);
          // console.log(colors);
          for (let i = 0; i < (this.formdata.users || []).length; i++) {
            const el = (this.formdata.users || [])[i];
            this.userlist[el._id] = el;
            this.userlist[el._id].color = colors[i];
          }
        } else throw new Error(dt.data.message);
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
      }).finally(() => {
        this.loading = false;
      })
    },
    addDialogBox() {
      this.addeditdata = {};
      this.addeditdata.amount1name = "Actuals";
      this.addeditdata.materialityamount1 = "0";
      this.addeditdata.materialitypercent1 = "2";
      this.adddialog = true;
    },
    addAnalysis() {
      this.addloading = true;
      this.axios.post("/v2/financialclose/analysis/addvarianceanalysis/" + this.metadata._id, { data: [this.addeditdata] }).then(dt => {
        if (dt.data.status === "success") {
          this.$store.commit("sbSuccess", "Analysis Added");
          this.adddialog = false;
          this.$nova.gotoLink({path: '/financialclose/project/'+this.id+'/varianceanalysis/analysis/'+dt.data.data[0]?._id})
          // this.refreshData();
        } else throw dt.data.message || "Error changing status";
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.addloading = false;
      });
    },

  },
  computed: {
  },
  watch: {
    "vafrom": function (d) {
      this.addeditdata.vafrom = d;
    },
    "vafromvalue": function (d) {
      this.addeditdata.vafromvalue = d;
    }
  }
}
</script>

